<template lang="html">
    <section class="container py-20">
        <article>
            <h1 class="text-xl md:text-3xl py-3 font-medium">IMPRESSUM NACH §5 TELEMEDIENGESETZ (TMG)</h1>
            <p>Fliesen & Naturstein&nbsp;Sarecz</p>
            <p>Brandmühlenstraße 4</p>
            <p>101731 Kreischa OT Kautzsch</p>
            <p>Inhaber: Attila Sarecz</p>
            <p>St.Nr.:203/265/00322</p>
            <p>Hwk-Nr.:048099</p>
            <p>Tel:<a href="tel:+4935206394880">+49 (0) 35206 394 880</a></p>
            <p>Fax:<a href="tel:+4935206394881">+49 (0) 35206 394 881</a></p>
            <a href="mailto:info@badwerk-dresden.de">info@badwerk-dresden.de</a>
            <p>Internet: <a href="https://www.badwerk-dresden.de">www.badwerk-dresden.de</a></p>

            <h2 class="text-xl md:text-2xl pt-5 pb-2 font-medium">HAFTUNGSAUSSCHLUSS / DISCLAIMER</h2>

            <h3 class="text-lg md:text-xl py-2 font-medium">Haftung für Inhalte</h3>

            <p>
                Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
                Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
                können wir jedoch keine Gewähr übernehmen. Als Dienstanbieter
                sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
                Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
                bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                verpflichtet, übermittelte oder gespeicherte fremde
                Informationen zu überwachen oder nach Umständen zu forschen, die
                auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                Entfernung oder Sperrung der Nutzung von Informationen nach den
                allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
                diese Inhalte umgehend entfernen.
            </p>

            <h3 class="text-lg md:text-xl py-3 font-medium">Haftung für Links</h3>

            <p>
                Unser Angebot enthält Links zu externen Webseiten Dritter, auf
                deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
                diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                Verlinkung nicht erkennbar. Eine permanente inhaltliche
                Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                umgehend entfernen.
            </p>

            <span>Quellenangaben:</span>
            <a href="https://www.e-recht24.de/muster-datenschutzerklaerung.htm">
                Datenschutzerklärung eRecht24
            </a>,
            <a href="https://www.e-recht24.de/muster-disclaimer.htm">
                Disclaimer eRecht24
            </a>
        </article>
    </section>
</template>
